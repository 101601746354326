import axios from "axios";
import { io } from "socket.io-client";

// export const baseURL = "http://localhost:4055";

export const baseURL = "https://kleopatramassage-beauty.com:4055";

export const baseUploadURL = baseURL + "/uploads/";
const user = localStorage.userKB ? JSON.parse(localStorage.userKB) : "";

export const HTTP = axios.create({
  baseURL: `${baseURL}/api/`,
  headers: {
    Authorization: `Bearer ${user.token}`,
  },
});

export const socket = io(baseURL);

socket.on("connect", () => {
  console.log("Connected");
});
